import React from "react";
import axios from "axios";
import Config from "../../Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withFormik } from 'formik';
import Compress from "react-image-file-resizer";
import ImageUploader from '../../Components/ImageUploader';
import validator from 'validator';
const NuevoEstudiante = (props) => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setValues,
        setFieldValue,
        resetForm
    } = props;

    const [courses,setCourses] = React.useState([]);
    const [tmp_photo,setTmpPhoto] = React.useState(null);
    const id  = props.match.params.id;
    

    console.log(id, 'mostrando id');

    React.useEffect(()=> {
        document.title = "myComply: New Student";
        

        if(id){
            values.ID = id;
            let url;
                
                let result = validator.isUUID(id);
                console.log(result);
                if(result){
                url = Config.url + "estudiantes/guid/" + id
                }else{
                url = Config.url + "estudiantes/oldid/" + id
                }
            axios.get(url)
            .then(response => {
                console.log(response);
                setTmpPhoto(response.data.foto);
                const fields = ['firstName', 'lastName', 'phone', 'email', 'foto', 'idNumber'];
                fields.forEach(field => setFieldValue(field, response.data[field], false));
                //setFieldValue('FirstName', 'Probando');
            })
            .catch(error => {
                console.log(error);
                toast.error('Error');
            })

        }

    },[]) 

    async function getCourses() {

        await axios.get(Config.url+'courses/', { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                console.log(response);
                setCourses(response.data);
            })
            .catch(error => {
                console.log(error);
                toast.error('Error');
            })

    }
    

    
    return (
        <>
            <ToastContainer />
            <form onSubmit={handleSubmit}>
                {/* INICIO DEL FORMULARIO */}
                <input type="hidden" onChange={(e)=>{handleChange(e);}} onBlur={handleBlur} name="ID" value={values.ID || null}/>
                <div className="row layout-top-spacing">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                        <div className="statbox widget box box-shadow">
                            <div className="widget-header">
                                <div className="row">
                                    <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                                        <h4>{id ? 'EDIT' : 'NEW'} STUDENT</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="widget-content widget-content-area">
                                <div className="row">
                                    {tmp_photo  && 
                                    <div className="col-lg-12 text-center">
                                        <div className="avatar avatar-xl">
                                            <img alt="avatar" src={tmp_photo} style={{width:300}} className="rounded-circle" />
                                        </div>
                                    </div>
                                    }
                                
                                    <div className="col-12">
                                        <div className="form-group mb-3">
                                            <label>Photo</label>
                                            <ImageUploader setTmpPhoto={setTmpPhoto} setFieldValue={setFieldValue}/>
                                            {/* <input type="file" className="form-control"
                                            onChange={(e)=>{onFileResize(e);}}
                                        /> */}
                                        
                                        </div>
                                        <div className="form-group mb-3">
                                            
                                            <input type="hidden" className="form-control"
                                            onChange={(e)=>{handleChange(e);}}
                                            onBlur={handleBlur}
                                            name="foto"
                                            value={values.foto || ''}
                                            />
                                            {errors.foto && touched.foto && errors.foto}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mb-3">
                                            <label>Expired Date</label>
                                            <input type="date" className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="expireDate"
                                            value={values.expireDate || ''}
                                            />
                                            {errors.expireDate && touched.expireDate && errors.expireDate}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mb-3">
                                            <label>First Name</label>
                                            <input type="text" className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="firstName"
                                            value={values.firstName || ''}
                                            />
                                            {errors.firstName && touched.firstName && errors.firstName}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mb-3">
                                            <label>Last Name</label>
                                            <input type="text" className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="lastName"
                                            value={values.lastName || ''}
                                            />
                                            {errors.lastName && touched.lastName && errors.lastName}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mb-3">
                                            <label>Email</label>
                                            <input type="email" className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="email"
                                            value={values.email || ''}
                                            />
                                            {errors.email && touched.email && errors.email}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mb-3">
                                            <label>Phone</label>
                                            <input type="text" className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="phone"
                                            value={values.phone || ''}
                                            />
                                            {errors.phone && touched.phone && errors.phone}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mb-3">
                                            <label>ID Number</label>
                                            <input type="text" className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="idNumber"
                                            value={values.idNumber || ''}
                                            />
                                            {errors.idNumber && touched.idNumber && errors.idNumber}
                                        </div>
                                    </div>
                                    
                                </div>
                                <button type="submit" className="btn btn-success" disabled={isSubmitting}>
                                        Submit
                                    </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default withFormik(
    {
        enableReinitialize: true,
        // default values
        mapPropsToValues: () => ({
            
        }),
        validate: values => {
            // validation
        },
        handleSubmit: async (values, {resetForm, setSubmitting }) => {

            console.log(values);
            console.log(values.ID);
            // return;
            if(values.ID){
                //edit mode
                
                await axios.put(Config.url+'estudiantes/'+values.ID, values, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } })
                .then(response => {
                    console.log(response);
                    toast.success('Edit Successfully');
                    setTimeout(()=>{
                        window.location.reload();
                    },3000)
                    
                    //resetForm();
    
                })
                .catch(error => {
                    console.log(error);
                    toast.error('Error.');
    
                })
            }else{
                //add mode
                await axios.post(Config.url+'estudiantes/', values, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } })
            .then(response => {
                console.log(response);
                toast.success('Successfully');
                setTimeout(()=>{
                    window.location.reload();
                },3000)
                resetForm();

            })
            .catch(error => {
                console.log(error);
                toast.error('Error.');

            })
            }
            


            // setTimeout(() => {

            //     alert(JSON.stringify(values, null, 2));

            //     setSubmitting(false);

            // }, 1000);

        },
    }
)(NuevoEstudiante);
