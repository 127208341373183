import React from "react";
import axios from "axios";
import Config from "../../Config";
import { useHistory } from "react-router-dom";
import LogoOsha from "../../assets/img/logoosha.PNG";
import LogoCheck from "../../assets/img/comprobado.png";
import LogoCheck2 from "../../assets/img/comprobado2.png";
import falltrained from "../../assets/img/falltrained.jpeg";
import drug from "../../assets/img/drug.jpeg";
import makeit from "../../assets/img/makeit.PNG";
import loveny from "../../assets/img/loveny.PNG";
import logos from "../../assets/img/logos.png";
import validator from 'validator';
//import logooshatrainer from '../../assets/img/logoosha2.jpeg';
import logomycomply from "../../assets/img/logov2.svg";
import logooshatrainer from "../../assets/img/logov2.svg";
//import { Link, RichText, Date } from "prismic-reactjs";
import '../../App.css';
import { Navbar, Container, Row, Col, Card, Button } from "react-bootstrap";
import { FaCheck } from 'react-icons/fa';
export default function VerRegistro(props) {
  const history = useHistory();
  const location = history;

  const [data, setData] = React.useState(null);
  const [dataCertificates, setDataCertificates] = React.useState([]);

  React.useEffect(() => {
    document.title = "myComply: Employee information for ";
    getRegistro();
  }, []);

  async function getRegistro() {

    let url;
    let id = props.match.params.id;
    let result = validator.isUUID(id);
    console.log(result);
    if(result){
      url = Config.url + "estudiantes/guid/" + id
    }else{
      url = Config.url + "estudiantes/oldid/" + id
    }
    

    await axios
      .get(url)
      .then((response) => {
        setData(response.data);
        //consultar curso
        document.title = "myComply: Employee information for " + response.data.FirstName + ' ' +response.data.LastName
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }


  console.log(props);
  return (
    <>
    {data && 
      <>
    <Navbar bg="light">
    <Navbar.Brand>
      <img src={logomycomply} alt="myComply logo" className="ml-2" />
    </Navbar.Brand>
    <Navbar.Text>SST Card powered by myComply</Navbar.Text>
  </Navbar>
  <Container>
    <Row>
      <Col xs={12} >
        <Card>
          <Card.Body>
            <Row style={{textAlign: 'center'}}>
            <Col md={2}>
            <img className="studenLogo" src={data && data.foto} style={{ width: 120 }}/>
            </Col>
            <Col md={10} className="verticalcenter">
            <h1>
              <span className="smallTitle">Hello, my name is</span>
              <br/>
              {data && data.firstName} {data && data.lastName}
            </h1>
            </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
    <br/>
    <Row>
      <Col xs={12}>
      <Card>
          <Card.Body>
            
            <Row style={{textAlign: 'center'}}>
              <Col xs={6}>
              <strong>SST Card Type</strong>
              </Col>
              <Col xs={6}>
              <strong>Expires On</strong>
              </Col>
            </Row>
            <Row style={{textAlign: 'center'}}>
              <Col xs={6}>
              SST Card Type
              </Col>
              <Col xs={6}>
              {data && new Date(data.expireDate).toLocaleDateString('en-En')}
              </Col>
            </Row>
            </Card.Body>
            </Card>

      </Col>
    </Row>
    <br/>
    {data && data.certificado.map((data, index)=>{
      return(
        <>
        <Row key={index}>
      <Col xs={12}>
      <Card bg="light">
      <Card.Header>
      <Row>
              <Col xs={8}>
              <strong>{data.curso.titulo}</strong> <br/>
              <span className="text-success"><FaCheck/> Verified</span>
              </Col>
              <Col xs={4} style={{textAlign:'right'}}>
                <Button href={"/badge/"+props.match.params.id+"/"+data.id} variant="light"><strong>DETAILS</strong></Button>
              </Col>
              
            </Row>

      </Card.Header>
          <Card.Body>
             <img className="placeholderCertificate" src={data.foto} style={{width: 300, margin: 'auto', display: 'block'}}/>
            
            
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col xs={6}>
                  Issued: {new Date(data.fechaEmision).toLocaleDateString('en-En')}
                </Col>
                <Col xs={6} style={{textAlign:'right'}}>
                  Expires: {new Date(data.fechaExpiracion).toLocaleDateString('en-En')}
                </Col>
              </Row>
            </Card.Footer>
            </Card>

      </Col>
    </Row>
    <br/>
        </>
      )
    })}
  </Container>
   </>
    }
     </> 
   
  );
}
